.about_heading{
    font-size: 18pt;
    font-weight: 900;
    color: #76071A;
}

.read_more_btn{
    background-color: #76071A;
    border-radius: unset;
    padding: 12px 30px !important;
}

@media screen and (max-width:768px){
    .toggle_read_more {
        display: block;
    }
    .hidden {
        display: none;
    }
    .about_heading{
        margin-top:24pt ;
        text-align: center;
    }
    .about_description{
        text-align:center;
        font-size: 11pt;
        margin-top: 7pt !important;
    }
    .about_column{
        text-align: center;
    }
    .about_btn_wrapper{
        margin-top: 10pt;
    }
}
@media screen and (min-width:769px){
    .about_btn_wrapper{
        display: none;
    }
}
@media screen and (min-width:769px) and (max-width:991px){
    .about_heading{
        text-align: center;
        margin-top: 20pt;
    }
    .about_description{
        margin-top:8pt !important;
        font-size: 11pt;
        text-align: center;
    }
    .about_column{
        text-align: center;
    }
    .about_btn_wrapper{
        margin-top: 10pt;
    }
}