
@media (min-width: 1400px) {
    .container {
        max-width: 1140px !important;
    }
}

.hero_section_parent_wrapper{
    background: url('./images/background_img.png');
    background-repeat: no-repeat;
    background-size: cover;
}
.hero_section_mask{
    background-size: 100% 100%;
    padding-top:8vh;
    padding-bottom:8vh;
    background: rgba(0, 0, 0, 0.912);
}
.hero_section_heading{
    font-weight: 900;
    font-size: 34pt;
}
.contact_btn{
    background-color: #6B1C29;
    border-radius: unset;
    padding: 12px 24px;
    font-weight:600;
}
.contact_btn:hover{
    background-color: #6B1C29;
}


@media screen and (max-width:769px){
    .hero_section_mask{
        background-size: 100% 100%;
        padding-top:16vh;
        padding-bottom:16vh;
        background: linear-gradient(270deg, rgba(1, 50, 152, 0.96) -79.05%, rgba(0, 0, 0, 0.00) -4.97%, rgba(1, 1, 1, 0.94) 37.08%, rgba(1, 1, 1, 0.96) 99.89%);
    }
    .hero_section_heading{
        font-size: 22pt;
        text-align: center;
    }
    .hero_section_description{
        text-align: center;
    }
    .btn-wrapper{
        text-align: center;
    }
    .service_heading{
        text-align:center;
    }
}

@media screen and (min-width:769px) and (max-width:991px){
    .hero_section_heading{
        font-size: 19pt;
    }
    .contact_btn{
        font-size: 9pt;
    }
}