.faq_heading{
    font-size: 18pt;
    font-weight: 900;
    color: #76071A;
}
.accordion-header{
    margin-top: 10pt !important;
}

.custom-accordion .accordion-button {
    background-color: #76071A; /* Set the button's background color */
    color: #fff;
    padding-top: 22pt;
    padding-bottom: 22pt; 
}

@media screen and (max-width:768px){
    .faq_heading{
        font-size:14pt;
        text-align: center;
    }
    .accordion-button{
        font-size: 9pt !important;
    }
    .faq_section_parent_wrapper{
        padding-left: 6px !important;
        padding-right: 6px !important;
    }
}