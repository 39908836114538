label{
    font-weight: 700 !important;
}
input{
    padding:10pt 20pt !important;
}
#button{
    background-color: #6B1C29;
}
.contact_caption{
    font-weight:bolder;
}

@media screen and (max-width:767px){
    .contact_form_section_parent_wrapper{
        padding: 0 !important;
    }
}