.services_section_parent_wrapper{
    background-color: #F5F5F5;
}
.services_card{
    border-radius: 5px;
    background: #FFF;
    box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 20pt;
}
.service_card_body{
    padding: 0 !important;
}
.services_heading{
    font-size: 18pt;
    font-weight: 900;
    color: #76071A;
}
.services_description{
    font-size: 11pt;
}
.read_more_service{
    color: #76071A !important;
    font-weight: 800;
    text-decoration: underline;
}
.read_more_btn:hover{
    background-color: #76071A;
}
.services_card_heading{
    font-weight: 700;
}

.services-container {
    overflow-x: auto;
    white-space: nowrap;
    max-width: 100%;
    padding: 20px; /* Add padding if needed */
  }
  
  .services-wrapper {
    display: inline-flex;
    gap: 20px; /* Adjust the gap between cards as needed */
  }


@media screen and (max-width:768px){
    .services_heading{
        text-align:center;
    }
    
}