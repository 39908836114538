.contact_section_parent_wrapper{
    background-color: black;
}
.contact_heading{
    font-size: 15pt;
}
li{
    font-weight: 300;
    font-size: 11pt;
}

@media screen and (max-width:768px){
    .contact_heading{
        text-align: center;
        font-size: 14pt;
    }
    li{
        text-align: center;
    }
}