.service_heading{
    color: black;
    font-weight: 800;
}


@media screen and (max-width:767px){
    .service_heading{
        text-align: center;
    }
    .service_description{
        text-align: center;
        margin-top: 6pt;
        font-size: 10pt;
    }
}

@media screen and (min-width:769px) and (max-width:990px){
    .service_heading{
        font-size: 14pt;
    }
    .service_description{
        font-size: 9pt;
    }
}