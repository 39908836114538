*{
  padding:0;
  margin:0;
  list-style-type:none ;
  text-decoration: none !important;
  font-family: Poppins, sans-serif;
}

@media (min-width: 1400px) {
  .container {
      max-width: 1140px !important;
  }
}
/*navigation coding start here*/
.navigation_parent_wrapper{
  background-color: #47292B;
}
.nav_list_items_wrapper{
  column-gap: 20px;
}
.contact_link{
  background-color: #6B1C29;
  padding: 10px 30px;
}
.nav_link_item{
  font-weight: 550;
  font-size: 10pt;
  color: white !important;
}
.home_link{
  color: white !important;
}

@media screen and (max-width:767px){
  .home_link{
      text-align:center !important;
  }
  .nav_list_items_wrapper{
    display: none;
  }
  .hide {
    display: none;
  }
  
  .show {
    display: flex; 
    flex-direction:column;
    height: 55vh;
    position:absolute;
    justify-content: center !important;
    top:16vh;
    left: 0;
    row-gap: 4vh;
    border-top: solid 1px white;
    background-color: #47292B;
  }
}
/*navigation coding ended here*/